<template>
    <div class="row g-5 g-xxl-8 mx-0 bank-follow-table">
        <div class="card mb-5 mb-xl-10 py-2">
            <div class="card-header px-0">
                <div class="card-title w-100 d-flex justify-content-between m-0">
                    <h3 class="fw-bolder m-0">
                        {{ $t("All Countries") }}
                        <span class="count-label ms-2">{{ bankDetails.allCountries.length }}</span>
                    </h3>

                    <!--          <div class="search-countries d-flex align-items-center">-->
                    <!--            <el-select-->
                    <!--                v-model="value"-->
                    <!--                multiple-->
                    <!--                filterable-->
                    <!--                default-first-option-->
                    <!--                :reserve-keyword="false"-->
                    <!--                :placeholder="$t('Search Countries')"-->
                    <!--                collapse-tags-->
                    <!--            >-->
                    <!--              <el-option-->
                    <!--                  v-for="(item, index) in countries"-->
                    <!--                  :key="index"-->
                    <!--                  :label="item"-->
                    <!--                  :value="item"-->
                    <!--              />-->
                    <!--            </el-select>-->
                    <!--          </div>-->

                </div>
            </div>

            <div class="table-wrapper">
                <el-table
                    :data="bankDetails.allCountries"
                    :show-header="false"
                    style="width: 100%">
                    <el-table-column prop="name" class-name="bank-follow-table__name">
                        <template #default="scope">
                            <h1>{{ scope.row.name }}</h1>
                        </template>
                    </el-table-column>
                    <el-table-column prop="score" class-name="bank-follow-table__num_1" width="80">
                        <template #default="scope">
                            <div class="follow-status" :class="getStatusClass(scope.row.score)">
                                {{ scope.row.score }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="importance" class-name="bank-follow-table__num_2" width="80">
                        <template #default="scope">
                            <div class="follow-status">
                                {{ scope.row.importance }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <CountriesProductAreasTable
            :title="$t('Data on more countries found')"
            :bg-image="'/media/buying/icons/wave-blue.png'"
            :tableData="bankDetails.noLicenceCountries"
        />
    </div>
</template>

<script>
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
import store from "@/store";
import { getStatusClass } from "@/core/helpers/GlobalHelper";
import { BankSettingsContainer } from "@/store/models/bank/BankSettingsContainer";
import CountriesProductAreasTable from "@/buying-teams/pages/bank/settings/bank/components/CountriesProductAreasTable";

export default {
    name: "Countries",
    components: {
        CountriesProductAreasTable,
        StatusComponent
    },
    props: {
        bankDetails: BankSettingsContainer
    },
    data() {
        return {
            getStatusClass,
            value: []
        }
    }
}
</script>
<style lang="scss" scoped>
.info-section-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
}
</style>
